import {
  CONTENT_TYPES,
  JOURNEY_CREATION_PAGES,
  initialState,
} from "./constant";
import {
  revalidate,
  revalidateCategory,
  revalidateInternal,
  revalidateJourneyDescription,
  revalidateJourneyName,
} from "./revalidate";
const { createSlice } = require("@reduxjs/toolkit");

const NewJourneySlice = createSlice({
  name: "NewJourneySlice",
  initialState,
  reducers: {
    selectFromScratch(state, {}) {
      state.page = JOURNEY_CREATION_PAGES.selectIsFromScratch;
      state.errors = {};
      state.touchPointList = [];
      state.internalTouchPointList = [];
      state.name = "";
      state.description = "";
      state.isJourneyOpen = true;
      state.id = undefined;
      state.category = undefined;
      state.publishedStatus = undefined;
    },
    createTemplate(state, {}) {
      state.page = JOURNEY_CREATION_PAGES.addDetails;
      state.errors = {};
      state.touchPointList = [];
      state.internalTouchPointList = [];
      state.category = undefined;
      state.name = "";
      state.id = undefined;
      state.description = "";
      state.isJourneyOpen = true;
      state.publishedStatus = undefined;
    },
    changeJourneyName(state, { payload: { name } }) {
      state.name = name;
      revalidateJourneyName(state, name);
    },
    changeJourneyCategory(state, { payload: { category } }) {
      state.category = category;
      revalidateCategory(state, category);
    },
    changeJourneyDescription(state, { payload: { description } }) {
      state.description = description;
      revalidateJourneyDescription(state, description);
    },
    toggleJourneyCreationPage(state, { payload: { isJourneyOpen } }) {
      state.isJourneyOpen = isJourneyOpen;
    },
    createFromScratch(state, { payload: { startingTouchPoint } }) {
      state.startingTouchPoint = startingTouchPoint;
      state.touchPointList = [
        {
          ...startingTouchPoint,
          selectedContent: CONTENT_TYPES.hub,
        },
      ];
      state.isJourneyOpen = false;
      state.selectedTouchPoint = 1;
      state.id = undefined;
      state.page = JOURNEY_CREATION_PAGES.touchPointConfiguration;
    },
    createFromTemplate(state, { payload: { startingTouchPoint } }) {
      state.startingTouchPoint = startingTouchPoint;
      state.touchPointList = [
        {
          ...startingTouchPoint,
          selectedContent: CONTENT_TYPES.hub,
        },
      ];
      state.id = undefined;
      state.isJourneyOpen = false;
      state.selectedTouchPoint = 1;
      state.page = JOURNEY_CREATION_PAGES.touchPointConfiguration;
    },
    createFromJourney(state, { payload: { journey } }) {
      state.page = journey.page;
      state.touchPointList = journey.touchPointList;
      state.name = journey.name;
      state.publishedStatus = journey.publishedStatus;
      state.category = journey.category;
      state.errors = {};
      state.id = journey.id;
      state.description = journey.description;
      state.selectedTouchPoint = 1;
      state.isJourneyOpen = journey.isJourneyOpen;
      state.internalTouchPointList = journey.internalTouchPointList;
      if (journey.internalTouchPointList?.length > 0) {
        state.selectedInternalTouchPoint = 1;
      }
    },
    changeContentType(state, { payload: { type, index } }) {
      state.touchPointList[index].selectedContent = type;
    },
    selectTouchPoint(state, { payload: { index } }) {
      state.selectedTouchPoint = index + 1;
    },
    addTouchPoint(state, { payload: { touchPoint } }) {
      state.touchPointList.push(touchPoint);
      state.selectedTouchPoint = state.touchPointList.length;
    },
    deleteTouchPoint(state, { payload: { index } }) {
      state.touchPointList.splice(index, 1);
      if (state.selectedTouchPoint > state.touchPointList.length) {
        state.selectedTouchPoint = state.touchPointList.length;
      }
    },
    changeFromEmail(state, { payload: { index, fromEmail } }) {
      state.touchPointList[index].emailContent.fromEmail = fromEmail;
      revalidate(state, index, state.isCompanyAdmin);
    },
    changeMinimumDaysToJoin(state, { payload: { index, minimumDaysToJoin } }) {
      state.touchPointList[index].hubContent.minimumDaysToJoin =
        minimumDaysToJoin;
      revalidate(state, index, state.isCompanyAdmin);
    },
    changeSubject(state, { payload: { index, subject } }) {
      state.touchPointList[index].emailContent.subject = subject;
      revalidate(state, index, state.isCompanyAdmin);
    },
    changeHtmlContent(state, { payload: { index, design, html } }) {
      state.touchPointList[index].emailContent.design = design;
      state.touchPointList[index].emailContent.html = html;
      revalidate(state, index, state.isCompanyAdmin);
    },
    // meeting touchpoints
    changeMeetingType(state, { payload: { index, meetingType } }) {
      state.touchPointList[index].hubContent.meetingType = meetingType;
      state.touchPointList[index].hubContent.meetingLocation = undefined;
      revalidate(state, index, state.isCompanyAdmin);
    },
    changeMeetingLocation(state, { payload: { index, meetingLocation } }) {
      state.touchPointList[index].hubContent.meetingLocation = meetingLocation;
      revalidate(state, index, state.isCompanyAdmin);
    },
    changeMeetingPerson(state, { payload: { index, meetingPerson } }) {
      state.touchPointList[index].hubContent.meetingPerson = meetingPerson;
      revalidate(state, index, state.isCompanyAdmin);
    },
    changeNotificationViaEmail(
      state,
      { payload: { index, notificationViaEmail } }
    ) {
      state.touchPointList[index].emailContent.notificationViaEmail =
        notificationViaEmail;
    },
    changeTitle(state, { payload: { index, title } }) {
      state.touchPointList[index].touchPointName = title;
      revalidate(state, index, state.isCompanyAdmin);
    },
    changeDescription(state, { payload: { index, description } }) {
      state.touchPointList[index].touchPointDescription = description;
      revalidate(state, index, state.isCompanyAdmin);
    },
    changeNotificationViaWhatsApp(
      state,
      { payload: { index, notificationViaWhatsApp } }
    ) {
      state.touchPointList[index].hubContent.notificationViaWhatsApp =
        notificationViaWhatsApp;
    },
    changeHubTemplate(state, { payload: { index, design, html } }) {
      state.touchPointList[index].hubContent.hubTemplate = { design, html };
      revalidate(state, index, state.isCompanyAdmin);
    },
    changePage(state, { payload: { page } }) {
      if (page === JOURNEY_CREATION_PAGES.fromTemplate) {
        state.isJourneyOpen = false;
      }
      state.page = page;
    },
    addNewTouchPoint(state, { payload: { touchPoint } }) {
      state.touchPointList.push(touchPoint);
      state.selectedTouchPoint = state.touchPointList.length;
      state.page = JOURNEY_CREATION_PAGES.touchPointConfiguration;
    },
    editHeaderDescription(state, { payload: { index, headerDescription } }) {
      state.touchPointList[index].hubContent.headerDescription =
        headerDescription;
      revalidate(state, index, state.isCompanyAdmin);
    },
    editFooterDescription(state, { payload: { index, footerDescription } }) {
      state.touchPointList[index].hubContent.footerDescription =
        footerDescription;
      revalidate(state, index, state.isCompanyAdmin);
    },
    addQuestion(state, { payload: { index, question } }) {
      state.touchPointList[index].hubContent.questions.push(question);
      revalidate(state, index, state.isCompanyAdmin);
    },
    editQuestion(state, { payload: { index, questionIndex, question } }) {
      state.touchPointList[index].hubContent.questions[questionIndex] =
        question;
      revalidate(state, index, state.isCompanyAdmin);
    },
    removeQuestion(state, { payload: { index, questionIndex } }) {
      state.touchPointList[index].hubContent.questions.splice(questionIndex, 1);
      revalidate(state, index, state.isCompanyAdmin);
    },
    editQuestionOptions(state, { payload: { index, questionIndex, options } }) {
      state.touchPointList[index].hubContent.questions[questionIndex].options =
        options;
      revalidate(state, index, state.isCompanyAdmin);
    },
    updateErrors(state, { payload: { errors } }) {
      state.errors = errors;
    },
    setIsCompanyAdmin(state, { payload: { isCompanyAdmin } }) {
      state.isCompanyAdmin = isCompanyAdmin;
    },
    setIsSuperAdmin(state, { payload: { isSuperAdmin } }) {
      state.isSuperAdmin = isSuperAdmin;
    },
    // internal touch point actions
    addInternalTouchPoint(state, { payload: { touchPoint } }) {
      state.internalTouchPointList.push(touchPoint);
      state.selectedInternalTouchPoint = state.internalTouchPointList.length;
    },
    deleteInternalTouchPoint(state, { payload: { index } }) {
      state.internalTouchPointList.splice(index, 1);
      if (
        state.selectedInternalTouchPoint > state.internalTouchPointList.length
      ) {
        state.selectedInternalTouchPoint = state.internalTouchPointList.length;
      }
    },
    selectInternalTouchPoint(state, { payload: { index } }) {
      state.selectedInternalTouchPoint = index + 1;
    },
    editInternalTouchPointTitle(state, { payload: { index, title } }) {
      state.internalTouchPointList[index].title = title;
      revalidateInternal(state, index, state.isCompanyAdmin);
    },
    editInternalTouchPointFrom(state, { payload: { index, from } }) {
      state.internalTouchPointList[index].from = from;
      revalidateInternal(state, index, state.isCompanyAdmin);
    },
    editInternalTouchPointTo(state, { payload: { index, to } }) {
      state.internalTouchPointList[index].to = to;
      revalidateInternal(state, index, state.isCompanyAdmin);
    },
    editInternalTouchPointCc(state, { payload: { index, cc } }) {
      state.internalTouchPointList[index].cc = cc;
    },
    editInternalTouchPointSubject(state, { payload: { index, subject } }) {
      state.internalTouchPointList[index].subject = subject;
      revalidateInternal(state, index, state.isCompanyAdmin);
    },
    editInternalTouchPointToEmail(state, { payload: { index, toEmail } }) {
      state.internalTouchPointList[index].toEmail = toEmail;
      revalidateInternal(state, index, state.isCompanyAdmin);
    },
    editInternalTouchPointCcEmail(state, { payload: { index, ccEmail } }) {
      state.internalTouchPointList[index].ccEmail = ccEmail;
      revalidateInternal(state, index, state.isCompanyAdmin);
    },
    editInternalTouchPointBody(state, { payload: { index, design, html } }) {
      state.internalTouchPointList[index].design = design;
      state.internalTouchPointList[index].html = html;
      revalidateInternal(state, index, state.isCompanyAdmin);
    },
    editInternalTouchPointAutoSchedule(
      state,
      { payload: { index, autoSchedule } }
    ) {
      state.internalTouchPointList[index].autoSchedule = autoSchedule;
    },
    editInternalTouchPointDays(state, { payload: { index, days } }) {
      state.internalTouchPointList[index].days = days;
    },
    editInternalTouchPointTiming(state, { payload: { index, timing } }) {
      state.internalTouchPointList[index].timing = timing;
    },
  },
});

export const {
  createFromScratch,
  createFromTemplate,
  changeContentType,
  addTouchPoint,
  selectTouchPoint,
  deleteTouchPoint,
  changeFromEmail,
  changeSubject,
  changeHtmlContent,
  changeMinimumDaysToJoin,
  changeNotificationViaEmail,
  changeTitle,
  changeDescription,
  changeNotificationViaWhatsApp,
  changeHubTemplate,
  changeJourneyName,
  changeJourneyCategory,
  changeJourneyDescription,
  toggleJourneyCreationPage,
  changePage,
  addNewTouchPoint,
  addQuestion,
  editQuestion,
  removeQuestion,
  editQuestionOptions,
  changeMeetingType,
  changeMeetingPerson,
  changeMeetingLocation,
  updateErrors,
  editHeaderDescription,
  editFooterDescription,
  selectFromScratch,
  setIsCompanyAdmin,
  setIsSuperAdmin,
  createFromJourney,
  createTemplate,
  addInternalTouchPoint,
  deleteInternalTouchPoint,
  selectInternalTouchPoint,
  editInternalTouchPointTitle,
  editInternalTouchPointFrom,
  editInternalTouchPointTo,
  editInternalTouchPointCc,
  editInternalTouchPointSubject,
  editInternalTouchPointBody,
  editInternalTouchPointAutoSchedule,
  editInternalTouchPointDays,
  editInternalTouchPointTiming,
  editInternalTouchPointToEmail,
  editInternalTouchPointCcEmail
} = NewJourneySlice.actions;

export default NewJourneySlice.reducer;
