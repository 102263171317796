import { getAuth, setAuth } from "services/identity.service";
import { refreshUserToken } from "services/user.service";

export const refreshTokenAndSetAuth = async () => {
    const auth = getAuth();
    const response = await refreshUserToken(auth.id, auth.refreshToken);
    if (response.status) {
      setAuth({
        id: res.entity.id,
        token: res.entity.token,
        refreshToken: res.entity.refreshToken,
      });
      return res;
    }
    return { status: false, message: "Request failed" };
};