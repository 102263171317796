import React from "react";
import PropTypes from "prop-types";
import Modal from "../molecules/Modal";
import Heading from "atoms/Heading";
import Button from "atoms/Button";
import Image from "next/image";
import Text from "atoms/Text";

const WarningModal = ({
  modalHeading = "Warning Modal",
  modalWidth = "max-w-md",
  modalInnerWidth = "max-w-xs",
  modalInnerAlignment = "text-center",
  modalButtonAlign = "justify-center",
  modalBtnBg = "bg-primary-600",
  modalSubHeading = "",
  modalHeadingClass = "",
  modalSubHeadingClass = "",
  isImgAvail = false,
  modalIconSrc = "/images/icons/danger-triangle.svg",
  modalIconWidth = 34,
  modalIconHight = 34,
  modalIconAlt = "",
  primaryBtnText = "Delete",
  outlineBtnText = "Cancel",
  showModal,
  closeModalPopup,
  onClick,
  cardRadius = "rounded-none",
  onClickSecondaryBtn,
  primaryBtnDisabled = false,
  zIndex = "z-50",
  showSecondaryBtn = true,
}) => {
  return (
    <>
      <Modal
        cardRadius={cardRadius}
        cardPadding="p-6"
        cardClass={`w-full m-auto ${modalWidth}`}
        showModal={showModal}
        closeModalPopup={closeModalPopup}
        zIndex={zIndex}
      >
        <div
          className={`w-full m-auto ${modalInnerWidth} ${modalInnerAlignment}`}
        >
          {isImgAvail && (
            <Image id='img_tag_144'
              src={modalIconSrc}
              width={modalIconWidth}
              height={modalIconHight}
              alt={modalIconAlt}
            />
          )}

          {modalHeading && (
            <Heading
              type="h6"
              className={`text-base mt-4 ${modalHeadingClass}`}
            >
              {modalHeading}
            </Heading>
          )}
          {modalSubHeading && (
            <Text
              className={`text-xs mt-4 text-gray-500 ${modalSubHeadingClass}`}
            >
              {modalSubHeading}
            </Text>
          )}

          <div className={`flex items-center mt-5 ${modalButtonAlign}`}>
            {showSecondaryBtn && (
              <Button
                variant="outlineBtn"
                btnClass="py-2 px-6"
                onClick={
                  onClickSecondaryBtn ? onClickSecondaryBtn : closeModalPopup
                }
              >
                {outlineBtnText}
              </Button>
            )}
            <Button
              variant="normalBtn"
              btnClass={`ml-2 py-2 px-6 rounded text-white ${modalBtnBg}`}
              onClick={onClick}
              disabled={primaryBtnDisabled}
            >
              {primaryBtnText}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default WarningModal;
WarningModal.propTypes = {
  showModal: PropTypes.bool,
  modalWidth: PropTypes.string,
  modalInnerWidth: PropTypes.string,
  modalInnerAlignment: PropTypes.string,
  modalButtonAlign: PropTypes.string,
  modalBtnBg: PropTypes.string,
  modalHeading: PropTypes.string,
  modalSubHeading: PropTypes.string,
  isImgAvail: PropTypes.any,
  showModal: PropTypes.bool,
  closeModalPopup: PropTypes.func,
  onClick: PropTypes.func,
  modalIconSrc: PropTypes.string,
  modalIconWidth: PropTypes.number,
  modalIconHeight: PropTypes.number,
  modalIconAlt: PropTypes.string,
  primaryBtnText: PropTypes.string,
  outlineBtnText: PropTypes.string,
  modalHeadingClass: PropTypes.string,
  modalSubHeadingClass: PropTypes.string,
};
