
export const JOURNEY_CREATION_PAGES = {
  selectIsFromScratch: "selectIsFromScratch",
  initial: "initial",
  touchPointConfiguration: "touchPointConfiguration",
  fromTemplate: "fromTemplate",
  addDetails: "addDetails",
};

export const CONTENT_TYPES = {
  hub: "hub",
  email: "email",
};

export const TOUCH_POINT_TYPES = {
  survey: "survey",
  meeting: "meeting",
  quiz: "quiz",
  content: "content",
};

const emailContent = {
  fromEmail: "",
  subject: "",
  design: "",
  html: "",
  notificationViaEmail: true,
}

const hubContent = {
  notificationViaWhatsApp : false,
  hubTemplate: {
    design: "",
    html: "",
  },
  minimumDaysToJoin: undefined,
}

export const surveyQuestionTypes = {
  rating: "rating",
  boolean: "boolean",
  singleSelect: "single select",
  multiSelect: "multi select",
  text: "text",
};

export const questionTypes = [
  { value: surveyQuestionTypes.text, label: "Text Question" },
  { value:surveyQuestionTypes.singleSelect, label: "Single Select Question" },
  { value: surveyQuestionTypes.multiSelect, label: "Multi Select Question" },
  { value: surveyQuestionTypes.rating, label: "Rating Question" },
  { value: surveyQuestionTypes.boolean, label: "Yes/No Question" },
];

export const sampleQuestions = {
  "single select": {
    options: [{ value: "" }],
  },
  "multi select": {
    options: [{ value: "" }],
  },
}

export const meetingTypes = {
  virtual : 'virtual',
  inPerson : 'in_person',
}

export const DEFAULT_TOUCH_POINT_CONTENTS = {
  content: {
    selectedContent: CONTENT_TYPES.hub,
    touchPointType: TOUCH_POINT_TYPES.content,
    touchPointName: "",
    touchPointDescription: "",
    emailContent: {...emailContent},
    hubContent: {...hubContent , minimumDaysToJoin : 0},
    touchPointTitle: "Content Touch point",
  },
  meeting: {
    selectedContent: CONTENT_TYPES.hub,
    touchPointType: TOUCH_POINT_TYPES.meeting,
    touchPointName: "",
    touchPointDescription: "",
    emailContent: {...emailContent},
    hubContent: {...hubContent , meetingType : meetingTypes.virtual },
    touchPointTitle: "Meeting Touch point",
  },
  survey: {
    selectedContent: CONTENT_TYPES.hub,
    touchPointType: TOUCH_POINT_TYPES.survey,
    touchPointName: "",
    touchPointDescription: "",
    emailContent: {...emailContent},
    hubContent: { questions : [] , ...hubContent , headerDescription : "" , footerDescription : "" , minimumDaysToJoin : 0},
    touchPointTitle: "Survey Touch point",
  },
  quiz: {
    selectedContent: CONTENT_TYPES.hub,
    touchPointType: TOUCH_POINT_TYPES.quiz,
    touchPointName: "",
    touchPointDescription: "",
    emailContent: {...emailContent},
    hubContent: { questions : [] , ...hubContent , headerDescription : "" , footerDescription : "" , minimumDaysToJoin : 0},
    touchPointTitle: "Quiz Touch point",
  },
};

export const defaultInternalTouchPoint = {
  title: "",
  from : undefined,
  to : undefined,
  cc : undefined,
  subject : "",
  design : "",
  html : "",
  autoSchedule : true,
  days: 0,
  timing : undefined,
};


export const initialState = {
  page: JOURNEY_CREATION_PAGES.initial,
  name: "",
  description: "",
  isJourneyOpen: true,
  touchPointList: [],
  internalTouchPointList: [],
  errors: {},
  isCompanyAdmin: false,
  category: undefined,
};

export const minimumCountOfOptions = 2;