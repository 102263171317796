import { companyQueries } from "./companyApis";
import { designationQueries } from "./designationApis";
import { companyMutations } from "./companyApis";
import { domoMutations, domoQueries } from "./domoApis";
import { JourneyTemplateMutations, JourneyTemplateQueries } from "./journeyTemplateApis";
import { positionQueries } from "./positionsApis";
import { userQueries } from "./userApis";

export const mutations = {
  ...domoMutations,
  ...JourneyTemplateMutations,
  ...companyMutations
}

export const queries = {
  ...domoQueries,
  ...JourneyTemplateQueries,
  ...positionQueries,
  ...companyQueries,
  ...userQueries,
  ...designationQueries
}
