import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { getCurrentCompanySlug } from "services/identity.service";
import { useRouter } from "next/router";
import ListItem from "atoms/ListItems";
import ListItemText from "atoms/ListItemText";
import { ArrowIcon } from "atoms/ArrowIcon";

const JourneysDropdown = ({ userDetails }) => {
  const router = useRouter();
  const slug = getCurrentCompanySlug(userDetails);

  const goToRoute = (str) => {
    router.push({
      pathname: `/[company]${str}`,
      query:
        str === "/admin/mytasks"
          ? { company: slug, tab: "pending" }
          : { company: slug },
    });
  };

  const [open, setOpen] = useState(false);
  const [wasOpened, setWasOpened] = useState(false);

  const journeyItems = [
    { primary: "My Journey", path: "/admin/journey-settings/my-journey" },
    {
      primary: "Journey Templates",
      path: "/admin/journey-settings/journey-templates",
    },
  ];

  const selectedIndex = useMemo(() => {
    return journeyItems.findIndex((item) =>
      router.pathname.includes(item.path)
    );
  }, [router.pathname, journeyItems]);

  useEffect(() => {
    const isJourneySettings = router.pathname.includes("journey-settings");

    if (isJourneySettings) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [router.pathname]);

  useEffect(() => {
    if (wasOpened && !router.pathname.includes("journey-settings")) {
      setOpen(false);
      setWasOpened(false);
    }
  }, [router.pathname]);

  const handleToggle = () => {
    if (!open) {
      goToRoute(journeyItems[0].path);
    }
    setOpen(!open);
    if (!open) {
      setWasOpened(true);
    }
  };

  const handleListItemClick = (index, path) => {
    goToRoute(path);
  };

  return (
    <div className="list">
      <ListItem
        onClick={handleToggle}
        selected={open}
        isParent={true}
        icon={
          open ? "/images/icons/speaker-o.svg" : "/images/icons/speaker.svg"
        }
      >
        <ListItemText primary="Journeys" selected={open} isParent={true} />
        <ArrowIcon>{open ? <ExpandLess /> : <ExpandMore />}</ArrowIcon>
      </ListItem>

      <div className={`collapse ${open && "open"}`}>
        {journeyItems.map((item, index) => (
          <ListItem
            key={index}
            selected={selectedIndex === index}
            onClick={() => handleListItemClick(index, item.path)}
          >
            <ListItemText
              primary={item.primary}
              selected={selectedIndex === index}
            />
          </ListItem>
        ))}
      </div>
    </div>
  );
};

JourneysDropdown.propTypes = {
  userDetails: PropTypes.object.isRequired,
};

export default JourneysDropdown;
