import { useState, useEffect } from "react";
import { useRouter } from "next/router";

const useSuperadminJourneysDropdown = () => {
  const router = useRouter();
  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);

  const journeyItems = [
    { primary: "Touch Points", path: "/superadmin/journey/touchpoints" },
    { primary: "Journey Templates", path: "/superadmin/journey/templates" },
    { primary: "Categories", path: "/superadmin/journey/categories" },
  ];

  useEffect(() => {
    const isJourneySettings = router.pathname.startsWith("/superadmin/journey/");
    setOpen(isJourneySettings);

    setSelectedIndex(
      isJourneySettings
        ? journeyItems.findIndex(({ path }) => router.pathname.startsWith(path))
        : null
    );
  }, [router.pathname]);

  const handleToggle = () => {
    if (!open) router.push(journeyItems[0].path);
    setOpen(!open);
  };

  const handleListItemClick = (index, path) => {
    setSelectedIndex(index);
    router.push(path);
  };

  return {
    open,
    selectedIndex,
    journeyItems,
    handleToggle,
    handleListItemClick,
  };
};

export default useSuperadminJourneysDropdown;

