import { Menu, MenuButton, MenuItem } from "@szhsin/react-menu";
import Image from "next/image";
import PropTypes from "prop-types";
import Avatar from "atoms/Avatar";
import Heading from "atoms/Heading";
import Text from "atoms/Text";
import { useRouter } from "next/router";
import { getFeatureFlags } from "lib/utils";

const Header = ({
  headerImgSrc = "/images/logo-black.png",
  headerImgWidth = 119,
  headerImgHeight = 23,
  headerImgAlt,
  avatarSrc = "/images/default-profile.svg",
  onLogOut,
  isLogoAvail = true,
  containerWidth = "container",
  className = "",
  padding = "py-3 md:py-5 lg:py-2",
  userDetails,
  isCandidate,
  isTitleAvail = false,
  title = "",
  companyDetails,
  isPreview,
}) => {
  const myLoader = ({ src }) => src;
  const flag = getFeatureFlags();
  const router = useRouter();

  return (
    <header
      className={`${
        !isPreview && "fixed top-0  left-0"
      } w-full  shadow-normal z-30 ${className} ${
        companyDetails?.slug === "fedex" ? padding : "pt-2 pb-1"
      } ${
        (isCandidate &&
        userDetails?.company?.theme?.headerType === "custom") ?
        "bg-primary-header" : "bg-white"
      }`}
      
    >
      <div className={`mx-auto px-4 ${containerWidth}`}>
        <div className="flex justify-between items-center">
          {isLogoAvail && (
              <img src={`${headerImgSrc}`} className="w-auto h-[30px] md:h-[40px] lg:h-[50px] cursor-pointer"
                   onClick={() => {
                isCandidate &&
                  router.push({
                    pathname: "/[company]/dashboard",
                    query: {
                      company: userDetails?.company?.slug,
                    },
                  });
              }}
            ></img>
          )}
          {isTitleAvail && <Heading type="h4">{title}</Heading>}
          <div></div>
          <Menu
            align={"end"}
            menuButton={
              <MenuButton className="ml-auto">
                <div className="flex items-center">
                  <Avatar
                    avatarSrc={avatarSrc}
                    avatarWidth={40}
                    avatarHeight={40}
                  ></Avatar>
                  <div className="ml-2">
                    <Image
                      id="img_tag_96"
                      src="/images/icons/downAngle.svg"
                      width="10"
                      height="10"
                      alt="Arrow"
                    />
                  </div>
                </div>
              </MenuButton>
            }
          >
            <MenuItem className="w-60  cursor-default">
              <div className="p-4 h-full w-full bg-gray-50 border border-gray-100 relative">
                {flag?.whatsapp_consent?.enabled && isCandidate && (
                  <div
                    className="absolute right-0 top-0 p-2 cursor-pointer"
                    onClick={() => {
                      router.push({
                        pathname: "/[company]/settings",
                        query: { company: userDetails?.company?.slug },
                      });
                    }}
                  >
                    <Image
                      id="img_tag_97"
                      src="/images/icons/setting.svg"
                      width="25"
                      height="25"
                      alt="Arrow"
                      className="cursor-pointer"
                    />
                  </div>
                )}
                <Heading type="h6" className="text-base">
                  {userDetails && userDetails.name}
                </Heading>
                <Text className="text-xs text-gray-400">
                  {userDetails && userDetails.emailId}
                </Text>
              </div>
            </MenuItem>
            <MenuItem className="w-60">
              <div
                className="flex p-4 text-sm text-gray-600"
                onClick={onLogOut}
              >
                <Image
                  id="img_tag_98"
                  src="/images/icons/logout.svg"
                  width="20"
                  height="20"
                  alt="edit"
                />
                <span className="ml-2.5">Sign out</span>
              </div>
            </MenuItem>
          </Menu>
        </div>
      </div>
    </header>
  );
};

export default Header;
Header.propTypes = {
  headerImgSrc: PropTypes.string,
  headerImgWidth: PropTypes.number,
  headerImgHeight: PropTypes.number,
  headerImgAlt: PropTypes.string,
  isLogoAvail: PropTypes.bool,
  avatarSrc: PropTypes.string,
  onLogOut: PropTypes.func,
  isLogoAvail: PropTypes.bool,
  containerWidth: PropTypes.string,
  className: PropTypes.string,
  padding: PropTypes.string,
  userDetails: PropTypes.object,
  isCandidate: PropTypes.bool,
  isTitleAvail: PropTypes.bool,
  title: PropTypes.string,
  companyDetails: PropTypes.any,
  isPreview: PropTypes.bool,
};
