import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import { Mutex } from "async-mutex";
import {
  API_END_POINT,
  STATE_CODES
} from "../constants/const";
import { getAuth, getCompanyId, getCompanyTimeZone, removeAuthOnInvalidToken } from "services/identity.service";
import { refreshTokenAndSetAuth } from "./refreshTokenAndSetAuth";

const mutex = new Mutex();
const baseUrl = `${API_END_POINT}`;

const baseQuery = fetchBaseQuery({
  baseUrl: baseUrl,
  prepareHeaders: (headers) => {
    const auth = getAuth();
    headers.set("x-access-token",  auth.token || "");
    headers.set("x-company-time-zone", getCompanyTimeZone());
    headers.set("x-company-id", getCompanyId() || null);
    return headers;
  },
});

const customFetchBase = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();

  let result = await baseQuery(args, api, extraOptions);

  if (result.error?.status === STATE_CODES.UNAUTHORIZED) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        const refreshResult = await refreshTokenAndSetAuth();
        if (refreshResult?.status) {
          // Retry the initial query
          result = await baseQuery(args, api, extraOptions);
        } else {
          removeAuthOnInvalidToken()
        }
      } finally {
        // release must be called once the mutex should be released again.
        release();
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};

export default customFetchBase;
