import Header from "organisms/Header";
import React, { useEffect, useState } from "react";

import Sidebar from "organisms/Sidebar";
import SuperAdminSidebar from "organisms/SuperAdminSidebar";
import { getCompanyId, removeAuth } from "services/identity.service";
import { useRouter } from "next/router";
import WarningModal from "organisms/WarningModal";
import Head from "next/head";
import CompanyHeader from "organisms/CompanyHeader";
import { magicLinkLoginNotificationChannel } from "constants";
import { userLogout } from "services/user.service";
import { Provider } from "react-redux";
import {store} from "../../redux/store";
import ToastAlert from "components/toast-message/components/ToastAlert";
// import { PersistGate } from 'redux-persist/integration/react'
import { ROLES } from "../../constants";

// persistance commented for temporary performance issue it will be added later

const Layout = (props) => {
  const router = useRouter();
  const companyId = getCompanyId();
  const [openWarningModal, setOpenWarningModal] = useState(false);
  const userDetails = props.children.props.currentUserDetails;
  const handleLogOut = async () => {
    const res = await userLogout(userDetails);
    if (res.status) {
      removeAuth();
      router.push("/admin/login");
    }
  };
  const isSuperAdmin = userDetails?.roles.some((role) => role.name === "Super Admin") && router.pathname.startsWith("/superadmin");
  const isSuperAdminCampaignManager = userDetails?.roles.some((role) => role.name === ROLES.SUPER_ADMIN_CAMPAIGN_MANAGER) && router.pathname.startsWith("/superadmin");

  const showOnlyHeader = !!userDetails?.showOnlyHeader;

  useEffect(() => {

    const channel = new BroadcastChannel(magicLinkLoginNotificationChannel);

    const handleRefreshMessage = (event) => {
      // when some tab get magic link this event will trigger 
      const { details: { company } , type } = event.data;
      if (type === "redirect") {

        if(company){
          // redirect the page
          router.push(`/${company}/admin/dashboard/` , undefined, { shallow: true }).then(() => {
            window.location.reload();
          })
        }else{
          router.push(`/admin/login`)
        }
      
      }
    };

    channel.addEventListener("message", handleRefreshMessage);

    // Clean up the event listener when the component is unmounted
    return () => {
      channel.removeEventListener("message", handleRefreshMessage);
      channel.close();
    };
  }, []);

  return (
    <React.Fragment>
      <div className="flex bg-gray-50 overflow-hidden">
        <Head>
          <link rel="icon" href="/images/favicon.ico" />
          <title>
            {isSuperAdmin ? "ANSR Loop - Super Admin" : isSuperAdminCampaignManager ? "ANSR Loop - Super Admin Campaign Manager" : "ANSR Loop - Admin"}
          </title>
        </Head>
        <ToastAlert />
        <Provider store={store}>
          {/* <PersistGate loading={null} persistor={persistor}> */}
            {isSuperAdmin || isSuperAdminCampaignManager ? (
              <SuperAdminSidebar userDetails={userDetails} />
            ) : (
              userDetails &&
              !showOnlyHeader && <Sidebar userDetails={userDetails} />
            )}
            
            <div className={`w-full ${!userDetails?.showOnlyHeader && "pl-64"}`}>
              <CompanyHeader
                userDetails={userDetails}
                isLogoAvail={showOnlyHeader}
                onLogOut={() => setOpenWarningModal(true)}
              />
              <div className="min-h-screen w-full overflow-y-auto pt-28 pr-8 pb-15 pl-6">
                {props.children}
                <WarningModal
                  modalWidth="max-w-xl"
                  modalInnerWidth="w-full"
                  modalInnerAlignment="text-left"
                  modalHeading="Are you sure you want to sign out?"
                  modalButtonAlign="justify-end mt-6"
                  primaryBtnText="Yes"
                  outlineBtnText="No"
                  showModal={openWarningModal}
                  closeModalPopup={() => setOpenWarningModal(false)}
                  onClick={handleLogOut}
                />
              </div>
            </div>
          {/* </PersistGate> */}
        </Provider>
      </div>
    </React.Fragment>
  );
};

export default Layout;
