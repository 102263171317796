import { validateInternalTouchPoint } from "./validateInternalTouchPoint"
import { validateTouchPoint } from "./validateTouchPoint"

export const revalidateJourneyName = (state , name) => {
    if(name && state.errors?.main){
        state.errors.main = {
            ...state.errors.main,
            title: ""
        }
    }
}

export const revalidateCategory = (state , category) => {
    if(category && state.errors?.main){
        state.errors.main = {
            ...state.errors.main,
            category: ""
        }
    }
}

export const revalidateJourneyDescription = (state , description) => {
    if(description && state.errors?.main){
        state.errors.main = {
            ...state.errors.main,
            description: ""
        }
    }
}

export const revalidate = (state , index , isCompanyAdmin) => {
    const errors = validateTouchPoint(state?.touchPointList?.[index] , isCompanyAdmin)
    if(state?.errors?.touchPointList?.[index]){
        state.errors.touchPointList[index] = errors
    }
    const allItemsUndefined = Object.values(state?.errors?.touchPointList || {}).every(item => item === undefined);
    if(allItemsUndefined){
        state.errors = {
            ...state.errors,
            touchPointList: undefined
        }
    }
}

export const revalidateInternal = (state , index , isCompanyAdmin) => {
    const errors = validateInternalTouchPoint(state?.internalTouchPointList?.[index] , isCompanyAdmin)
    if(state?.errors?.internalTouchPointList?.[index]){
        state.errors.internalTouchPointList[index] = errors
    }
    const allItemsUndefined = Object.values(state?.errors?.internalTouchPointList || {}).every(item => item === undefined);
    if(allItemsUndefined){
        state.errors = {
            ...state.errors,
            internalTouchPointList: undefined
        }
    }
}