import { tags } from "../constants";
import {
  ADD_SUPER_ADMIN_TOUCHPOINT_URL,
  GENERATE_SURVEY_REPORT,
  GET_SENDERS,
  GET_MY_JOURNEYS_URL,
  MARK_AS_DEFAULT_CAMPAIGN_TEMPLATE_URL,
  UPDATE_CAMPAIGN_TEMPLATE_STATUS_URL,
  GET_TOUCHPOINTS,
  GET_TOUCHPOINT_BY_ID,
  ADD_JOURNEY,
  GET_JOURNEY,
  UPDATE_CAMPAIGN_TEMPLATE_URL,
  GET_JOURNEY_CATEGORY,
  EDIT_JOURNEY,
  UPDATE_SUPER_ADMIN_TOUCHPOINT_STATUS_URL,
  ADD_JOURNEY_CATEGORY,
  GET_JOURNEY_CATEGORY_BY_ID,
  UPDATE_JOURNEY_CATEGORY,
  GET_MEETING_LOCATION_URL,
  UPDATE_SUPER_ADMIN_TOUCHPOINT_URL,
  GET_ADMIN_JOURNEY,
} from "services/url.service";
import {
  commonTransform,
  formatGetTouchPointTemplate,
  formatGetTouchPointTemplateList,
  getSendersTransform,
  globalTransform,
  journeyCategoryTransformer,
  meetingLocationTransformer,
} from "../handleTransformation";
import { getCompanyId } from "services/identity.service";

export const JourneyTemplateMutations = {
  generateCampaignTemplateReport: {
    getUrl: (campaignTemplateId) => GENERATE_SURVEY_REPORT(campaignTemplateId),
    transformResponse: (res) => commonTransform(res),
    method: "GET",
  },
  updateCampaignTemplateStatus: {
    getUrl: (id) => UPDATE_CAMPAIGN_TEMPLATE_STATUS_URL(id),
    transformResponse: (res) => commonTransform(res),
    method: "PUT",
    invalidatesTags: [tags.CAMPAIGN_TEMPLATES],
  },
  markAsDefaultCampaign: {
    getUrl: (companyId) => MARK_AS_DEFAULT_CAMPAIGN_TEMPLATE_URL(companyId),
    transformResponse: (res) => commonTransform(res),
    method: "POST",
    invalidatesTags: [tags.CAMPAIGN_TEMPLATES],
  },
  getTouchPointById: {
    getUrl: (id) => GET_TOUCHPOINT_BY_ID(id),
    transformResponse: (res) => commonTransform(res),
    method: "GET",
  },
  addSuperAdminTouchpoint: {
    getUrl: () => ADD_SUPER_ADMIN_TOUCHPOINT_URL(),
    transformResponse: (res) => commonTransform(res),
    method: "POST",
    invalidatesTags: [tags.SUPER_ADMIN_TOUCHPOINTS],
  },
  addJourney: {
    getUrl: (id) => ADD_JOURNEY(id),
    transformResponse: (res) => commonTransform(res),
    method: "POST",
    invalidatesTags: [tags.CAMPAIGN_TEMPLATES],
  },
  updateSuperAdminTouchpoint: {
    getUrl: ({ id }) => UPDATE_SUPER_ADMIN_TOUCHPOINT_URL(id),
    transformResponse: (res) => commonTransform(res),
    method: "PUT",
    invalidatesTags: [tags.SUPER_ADMIN_TOUCHPOINTS],
  },
  editJourney: {
    getUrl: EDIT_JOURNEY,
    transformResponse: (res) => commonTransform(res),
    method: "PUT",
    invalidatesTags: [tags.CAMPAIGN_TEMPLATES],
  },
  updateSuperAdminTouchpointStatus: {
    getUrl: ({ id }) => UPDATE_SUPER_ADMIN_TOUCHPOINT_STATUS_URL(id),
    transformResponse: (res) => commonTransform(res),
    method: "PUT",
    invalidatesTags: [tags.SUPER_ADMIN_TOUCHPOINTS],
  },
  addSuperAdminJourneyCategory: {
    getUrl: () => ADD_JOURNEY_CATEGORY(),
    transformResponse: (res) => globalTransform(res),
    method: "POST",
    invalidatesTags: [tags.JOURNEY_CATEGORIES],
  },
  updateSuperAdminJourneyCategory: {
    getUrl: (id) => UPDATE_JOURNEY_CATEGORY(id),
    transformResponse: (res) => globalTransform(res),
    method: "PUT",
    invalidatesTags: [tags.JOURNEY_CATEGORIES],
  },
  getJourneyCategoryById: {
    getUrl: (id) => GET_JOURNEY_CATEGORY_BY_ID(id),
    transformResponse: (res) => globalTransform(res),
    method: "GET",
  },
};

export const JourneyTemplateQueries = {
  myJourneys: {
    getUrl: ({ companyId, offset, search, sort, status, filter , limit = 10 }) => {
      const filters = JSON.stringify(filter);
      return GET_MY_JOURNEYS_URL(
        companyId,
        offset,
        search,
        sort,
        status,
        filters,
        limit
      );
    },
    transformResponse: (res) => commonTransform(res),
    providesTags: [tags.CAMPAIGN_TEMPLATES],
    method: "GET",
  },
  getSenders: {
    getUrl: ({ companyId, search }) => GET_SENDERS(companyId, ""),
    transformResponse: (res) => getSendersTransform(res),
    method: "GET",
  },
  getTouchPoints: {
    getUrl: () => GET_TOUCHPOINTS(),
    transformResponse: (res) => formatGetTouchPointTemplate(res),
    method: "GET",
  },
  getJourneyTemplates: {
    getUrl: ({ offset, search, sort, status }) => {
      return GET_JOURNEY(offset, search, sort, status);
    },
    transformResponse: (res) => commonTransform(res),
    providesTags: [tags.CAMPAIGN_TEMPLATES],
    method: "GET",
  },
  getAdminJourneyTemplates: {
    getUrl: () => GET_ADMIN_JOURNEY(),
    transformResponse: (res) => commonTransform(res),
    providesTags: [tags.CAMPAIGN_TEMPLATES],
    method: "GET",
  },
  getCampaignTemplateById: {
    getUrl: (templateId) => UPDATE_CAMPAIGN_TEMPLATE_URL(templateId),
    transformResponse: (res) => commonTransform(res),
    providesTags: [tags.CAMPAIGN_TEMPLATES],
    method: "GET",
  },
  getJourneyCategory: {
    getUrl: () => GET_JOURNEY_CATEGORY(),
    transformResponse: (res) => journeyCategoryTransformer(res),
    method: "GET",
  },
  getTouchPointsList: {
    getUrl: ({ search, filter, offset, limit }) => {
      const filters = JSON.stringify(filter);
      const listing = "true";
      return GET_TOUCHPOINTS(search, filters, offset, listing, limit);
    },
    transformResponse: (res) => formatGetTouchPointTemplateList(res),
    providesTags: [tags.SUPER_ADMIN_TOUCHPOINTS],
    method: "GET",
  },
  getSuperAdminJourneyCategory: {
    getUrl: ({ search, offset, limit }) =>
      GET_JOURNEY_CATEGORY(search, offset, limit),
    transformResponse: (res) => globalTransform(res),
    providesTags: [tags.JOURNEY_CATEGORIES],
    method: "GET",
  },
  getMeetingLocations: {
    getUrl: () => {
      const companyId = getCompanyId();
      return GET_MEETING_LOCATION_URL(companyId, "", undefined, undefined);
    },
    transformResponse: (res) => meetingLocationTransformer(res),
    method: "GET",
  },
};
