import React from 'react';
import PropTypes from 'prop-types';

const ListItemText = ({ primary, selected, isParent }) => (
  <div className={`text ${isParent ? 'parent' : ''} ${selected ? 'selected' : ''}`}>
    {primary}
  </div>
);

ListItemText.propTypes = {
  primary: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  isParent: PropTypes.bool,
};

ListItemText.defaultProps = {
  selected: false,
  isParent: false,
};

export default ListItemText;
