import { getCompanyId } from "services/identity.service";
import { commonTransform } from "../handleTransformation";
import { GET_POSITIONS_URL } from "services/url.service";
import { tags } from "../constants";

export const positionMutations = {};

export const positionQueries = {
  getPositions : {
    getUrl: ({ search, offset, limit, sort, filters, companyId } ) => {
        return GET_POSITIONS_URL(companyId, search, offset, limit, sort, filters)
    },
    transformResponse: (res) => commonTransform(res),
    method: "GET",
    providesTags: [tags.POSITION],
  },
};
