const ApiUrl = process.env.NEXT_PUBLIC_API_URL;

const UrlParamsReplace = (url, params = {}, queryParams = {}) => {
  let urlWithPrefix = `${ApiUrl}${url}`;
  if (params) {
    Object.keys(params).forEach(
      (key) => (urlWithPrefix = urlWithPrefix.replace(`:${key}`, params[key]))
    );
  }
  const queryParamsWithoutNull = {};
  if (queryParams) {
    Object.keys(queryParams).forEach((key) => {
      if (queryParams[key] !== undefined && queryParams[key] !== null) {
        queryParamsWithoutNull[key] = queryParams[key];
      }
    });
    const urlSearchParams = new URLSearchParams(queryParamsWithoutNull);
    urlWithPrefix += `?${urlSearchParams.toString()}`;
  }
  return urlWithPrefix;
};

// user
export const USER_LOGIN = UrlParamsReplace("/auth/mobile/sendOTP");
export const USER_SESSION = UrlParamsReplace("/auth/mobile/user");
export const USER_LOGOUT = UrlParamsReplace("/auth/user/logout");
export const MAP_RECRUITER = ({ companyId, userId }) =>
  UrlParamsReplace("/companies/:companyId/users/:userId/mapping", {
    companyId,
    userId,
  });

export const GET_USERS_URL = (
  companyId,
  search,
  offset,
  limit,
  filters,
  sort
) =>
  UrlParamsReplace(
    "/companies/:companyId/users",
    {
      companyId,
    },
    {
      search,
      offset,
      limit,
      filters,
      sort,
    }
  );

export const GET_USER_FILTERS_URL = (
  companyId,
  filters
) =>
  UrlParamsReplace(
    "/users-filters/company/:companyId",
    {
      companyId,
    },
    {
      filters,
    }
  );
export const GET_USER_BY_ID_URL = (userId) =>
  UrlParamsReplace("/users/:userId", { userId });
export const GET_USER_BY_ID_AND_COMPANY_ID_URL = (userId, companyId) =>
  UrlParamsReplace("/users/:userId/company/:companyId", { userId, companyId });
export const GET_COMPANY_BY_USER_ID = (userId) =>
  UrlParamsReplace("/users/:userId/company", { userId });
export const GET_USER_BY_ID_NO_AUTH_URL = (userId) =>
  UrlParamsReplace("/users/:userId/noAuth", { userId });
export const REFRESH_USER_TOKEN = (userId) =>
  UrlParamsReplace("/refresh-token/:userId", { userId });
export const ADD_USER_URL = (companyId) =>
  UrlParamsReplace("/companies/:companyId/users", { companyId });
export const UPDATE_USER_URL = (companyId, userId) =>
  UrlParamsReplace("/companies/:companyId/users/:userId", {
    companyId,
    userId,
  });
export const GET_ALL_USERS_URL = (search, filters, offset) =>
  UrlParamsReplace("/users", {}, { search, filters, offset });
export const ADD_USER_FROM_ADMIN = UrlParamsReplace("/users");
export const UPDATE_USER_FROM_ADMIN = (userId) =>
  UrlParamsReplace("/users/:userId", { userId });
export const GET_USER_BY_ROLE_ID_AND_COMPANY_ID = (roleId, companyId) =>
  UrlParamsReplace("/roles/:roleId/company/:companyId", { roleId, companyId });

// candidate
export const CANDIDATE_LOGIN = UrlParamsReplace(
  "/auth/mobile/sendCandidateOTP"
);
export const CANDIDATE_SESSION = UrlParamsReplace("/auth/mobile/candidate");
export const CANDIDATE_LOGOUT = UrlParamsReplace("/auth/candidate/logout");

export const CANDIDATE_CONFIDENCE_SCORE = (companyId) =>
  UrlParamsReplace("/companies/:companyId/candidates-confidence-score", {
    companyId,
  });
export const CANDIDATE_CONVERTED_STATUS = ({ companyId, filters }) =>
  UrlParamsReplace(
    "/companies/:companyId/candidate-converted-status",
    {
      companyId,
    },
    {
      filters,
    }
  );

export const CANDIDATE_ENGAGEMENT_SCORE = ({
  companyId,
  filters,
  offerAccepted,
}) =>
  UrlParamsReplace(
    "/companies/:companyId/candidates-engagement-score",
    {
      companyId,
    },
    {
      filters,
      offerAccepted,
    }
  );
export const GET_CANDIDATE_TOUCHPOINT_INSTANCES = (
  candidateId,
  search,
  touchPointStatus,
  offset,
  sort,
  limit
) =>
  UrlParamsReplace(
    "/touchpoint-instances/candidates/:candidateId",
    {
      candidateId,
    },
    {
      search,
      touchPointStatus,
      offset,
      sort,
      limit,
    }
  );
export const ADD_CANDIATE_URL = (companyId, search, offset, sort, filters) =>
  UrlParamsReplace(
    "/companies/:companyId/candidates",
    {
      companyId,
    },
    {
      search,
      offset,
      sort,
      filters,
    }
  );
export const GET_CANDIDATE_REPORT = (companyId, selectedValue) => {
  return UrlParamsReplace(
    "/companies/:companyId/candidates/high-level-reports",
    {
      companyId,
    },
    {
      selectedValue,
    }
  );
};

export const GET_CANDIDATE_CCE_REPORT = (
  companyId,
  campaignTemplateId,
  selectedValues
) => {
  return UrlParamsReplace(
    "/reports/cce-status/:companyId/:campaignTemplateId",
    {
      companyId: companyId,
      campaignTemplateId: campaignTemplateId,
    },
    {
      selectedValues: selectedValues,
    }
  );
};

export const UPDATE_CANDIATE_URL = (candidateId) =>
  UrlParamsReplace("/candidates/:candidateId", { candidateId });

export const GET_CANDIATE_URL = (candidateId) =>
  UrlParamsReplace("/candidates/:candidateId", { candidateId });

export const UPDATE_CANDIATE_STATUS_URL = (candidateId) =>
  UrlParamsReplace("/candidates/:candidateId/status", { candidateId });

export const VALIDATE_CANDIDATES_FROM_CSV_URL = (companyId) =>
  UrlParamsReplace("/companies/:companyId/bulk-candidates-validations", {
    companyId,
  });

export const ADD_BULK_CANDIDATES_FROM_CSV_URL = (companyId) =>
  UrlParamsReplace("/companies/:companyId/bulk-candidates", { companyId });
export const DEACTIVATE_CANDIDATES_URL = UrlParamsReplace(
  "/candidates/deactivate"
);

export const ADD_RECRUITER_SCORE = (companyId) =>
  UrlParamsReplace("/companies/:companyId/recruiter-score", { companyId });

export const DELETE_RECRUITER_SCORE = (companyId, scoreId) =>
  UrlParamsReplace("/companies/:companyId/recruiter-score/:scoreId", {
    companyId,
    scoreId,
  });

export const EDIT_RECRUITER_SCORE = (companyId, scoreId) =>
  UrlParamsReplace("/companies/:companyId/recruiter-score/:scoreId", {
    companyId,
    scoreId,
  });

export const UPDATE_CANIDATE_WHATSAPP_CONSENT = (candidateId) =>
  UrlParamsReplace("/candidates/:candidateId/whatsapp-consent", {
    candidateId,
  });

// designations
export const GET_DESIGNATIONS_URL = (companyId, search, offset, limit, sort) =>
  UrlParamsReplace(
    "/companies/:companyId/designations",
    {
      companyId,
    },
    {
      search,
      offset,
      limit,
      sort,
    }
  );

export const UPDATE_DESIGNATION_URL = (designationId) =>
  UrlParamsReplace("/designations/:designationId", { designationId });

//  position
export const ADD_POSITION_URL = (companyId) =>
  UrlParamsReplace("/companies/:companyId/positions", { companyId });
export const UPDATE_POSITION_URL = (positionId) =>
  UrlParamsReplace("/positions/:positionId", { positionId });
export const GET_POSITIONS_URL = (
  companyId,
  search,
  offset,
  limit,
  sort,
  filters
) =>
  UrlParamsReplace(
    "/companies/:companyId/positions",
    {
      companyId,
    },
    {
      search,
      offset,
      limit,
      sort,
      filters,
    }
  );
export const GET_POSITION_BY_ID_URL = (positionId) =>
  UrlParamsReplace("/positions/:positionId", { positionId });

export const UPDATE_POSITION_STATUS_URL = (positionId) =>
  UrlParamsReplace("/positions/:positionId/status", { positionId });

export const VALIDATE_POSITIONS_FROM_CSV_URL = (companyId) =>
  UrlParamsReplace("/companies/:companyId/bulk-positions-validations", {
    companyId,
  });

export const ADD_BULK_POSITIONS_FROM_CSV_URL = (companyId) =>
  UrlParamsReplace("/companies/:companyId/bulk-positions", { companyId });
export const DEACTIVATE_POSITIONS_URL = UrlParamsReplace(
  "/positions/deactivate"
);

// campaign template
export const CREATE_CAMPAIGN_TEMPLATE_URL = (companyId) =>
  UrlParamsReplace("/companies/:companyId/campaign-templates", { companyId });
export const GET_CAMPAIGN_TEMPLATES_URL = (
  companyId,
  offset,
  search,
  sort,
  status,
  filters
) =>
  UrlParamsReplace(
    "/companies/:companyId/campaign-templates",
    {
      companyId,
    },
    {
      offset,
      search,
      status,
      sort,
      filters,
    }
  );
export const GET_MY_JOURNEYS_URL = (
  companyId,
  offset,
  search,
  sort,
  status,
  filters,
  limit,
) =>
  UrlParamsReplace(
    "/companies/:companyId/my-journeys",
    {
      companyId,
    },
    {
      offset,
      search,
      status,
      sort,
      filters,
      limit,
    }
  );
export const UPDATE_CAMPAIGN_TEMPLATE_URL = (templateId) =>
  UrlParamsReplace("/campaign-templates/:templateId", {
    templateId,
  });
export const UPDATE_CAMPAIGN_TEMPLATE_STATUS_URL = (templateId) =>
  UrlParamsReplace("/campaign-templates/:templateId/status", {
    templateId,
  });
export const GET_CAMPAIGN_TEMPLATE_REPORTS = (companyId, search, filters) =>
  UrlParamsReplace(
    "/companies/:companyId/campaignReports",
    {
      companyId,
    },
    {
      search,
      filters,
    }
  );

export const GET_TOP_CAMPAIGN_TEMPLATES = (companyId, filters, limit) =>
  UrlParamsReplace(
    "/companies/:companyId/topCampaignTemplates",
    {
      companyId,
    },
    {
      filters,
      limit,
    }
  );

//mark as default campaign tempalte

export const MARK_AS_DEFAULT_CAMPAIGN_TEMPLATE_URL = (companyId) => {
  return UrlParamsReplace("/companies/:companyId/campaign-templates/default", {
    companyId,
  });
};
// touch point instances
export const GET_ALL_TASKS = (
  companyId,
  search,
  touchPointStatus,
  offset,
  filters,
  sort
) =>
  UrlParamsReplace(
    "/company/:companyId/touchpoint-instances",
    { companyId },
    {
      search,
      touchPointStatus,
      offset,
      filters,
      sort,
    }
  );
export const GET_MY_TASKS = (
  ownerId,
  companyId,
  search,
  touchPointStatus,
  offset,
  filters,
  sort
) =>
  UrlParamsReplace(
    "/company/:companyId/touchpoint-instances/owners/:ownerId",
    { companyId, ownerId },
    {
      search,
      touchPointStatus,
      offset,
      filters,
      sort,
    }
  );
export const GET_TOUCHPOINT_INSTANCE = (id) =>
  UrlParamsReplace("/touchpoint-instances/:id", { id });
export const RUN_TOUCHPOINT_INSTANCE = (id) =>
  UrlParamsReplace("/touchpoint-instances/:id/run", { id });
export const MARK_AS_COMPLETE_TOUCHPOINT_INSTANCE = (id) =>
  UrlParamsReplace("/touchpoint-instances/:id/completed", { id });
export const RATE_TOUCHPOINT_INSTANCE = (id) =>
  UrlParamsReplace("/touchpoint-instances/:id/rate", { id });
export const DEACTIVATE_TOUCHPOINT_INSTANCES = UrlParamsReplace(
  "/touchpoint-instances/deactivate"
);
export const GET_BULK_TOUCHPOINT_INSTANCES = (companyId) =>
  UrlParamsReplace("/companies/:companyId/touchpoint-instances/bulk", {
    companyId,
  });
export const BULK_UPDATE_TOUCHPOINT_INSTANCES = UrlParamsReplace(
  "/touchpoint-instances/bulk/edit"
);
export const UPDATE_TOUCHPOINT_STATUS = (id) =>
  UrlParamsReplace("/touchpoint-instances/:id/touchPointStatus", { id });
export const GET_TOUCHPOINT_INSTANCE_EFFECTIVENESS = (companyId, filters) =>
  UrlParamsReplace(
    "/companies/:companyId/tasks-effectiveness",
    { companyId },
    { filters }
  );

export const ENGAGEMENT_TIME_URL = UrlParamsReplace("/engagement-time");
export const ADD_ENGAGEMENT_ACTIVITY = UrlParamsReplace("/engagement-metric");

export const GET_PUBLISHED_NEWS_FEED = (companyId) =>
  UrlParamsReplace("/companies/:companyId/news-feeds/published", { companyId });

export const GET_LINKEDIN_POSTS = (companyId) =>
  UrlParamsReplace("/companies/:companyId/linkedinPost", { companyId });

export const GET_NEWS_FEEDS = (companyId, offset, search, sort) =>
  UrlParamsReplace(
    "/companies/:companyId/news-feeds",
    {
      companyId,
    },
    {
      offset,
      search,
      sort,
    }
  );
export const GET_PRE_SIGNED_URL = UrlParamsReplace("/aws/get-pre-signed-url");

export const GET_SIGNED_URL = (fileKey) =>
  UrlParamsReplace("/aws/get-s3-signed-url", {}, { fileKey });
export const GET_UPLOAD_PRE_SIGNED_URL = UrlParamsReplace(
  "/aws/get-upload-pre-signed-url"
);
export const CREATE_NEWS_FEED = (companyId) =>
  UrlParamsReplace("/companies/:companyId/news-feeds", { companyId });

export const GET_NEWS_FEED = (newsFeedId) =>
  UrlParamsReplace("/news-feeds/:newsFeedId", { newsFeedId });

export const GET_ROLES = (allRoles) =>
  UrlParamsReplace("/roles", {}, { allRoles });
// company
export const GET_COMPANIES_URL = (
  search,
  offset,
  limit,
  filters,
  includeUsers
) =>
  UrlParamsReplace(
    "/companies",
    {},
    { search, offset, limit, filters, includeUsers }
  );

export const ADD_COMPANY_URL = UrlParamsReplace("/company");

export const GET_COMPANY_URL = (companyId) =>
  UrlParamsReplace("/company/:companyId", {
    companyId,
  });
export const GET_COMPANY_NO_AUTH = (slug) =>
  UrlParamsReplace("/company/:slug/noAuth", {
    slug,
  });
export const UPDATE_COMPANY_CUSTOM_ATTRIBUTE_URL = (companyId) =>
  UrlParamsReplace("/companies/:companyId/custom-attributes", {
    companyId,
  });

export const UPDATE_COMPANY_THEMES = (companyId) =>
  UrlParamsReplace("/companies/:companyId/themes", {
    companyId,
  });

export const UPDATE_COMPANY_SETTINGS = (companyId) =>
    UrlParamsReplace("/companies/:companyId/settings", {
      companyId,
  });

export const GET_TASK_ERRORS_URL = (companyId, search, offset, filters) =>
  UrlParamsReplace(
    "/companies/:companyId/task-errors",
    {
      companyId,
    },
    {
      search,
      offset,
      filters,
    }
  );
export const GET_MY_TASK_ERRORS_URL = (ownerId, search, offset, filters) =>
  UrlParamsReplace(
    "/touchpoint-instances/owners/:ownerId/task-errors",
    {
      ownerId,
    },
    {
      search,
      offset,
      filters,
    }
  );
export const UPDATE_TASK_ERRORS_URL = (taskErrorId) =>
  UrlParamsReplace(
    "/touchpoint-instances/task-errors/:taskErrorId",
    {
      taskErrorId,
    },
    {}
  );
export const RETRY_TASK_ERRORS_URL = (taskErrorId) =>
  UrlParamsReplace(
    "/touchpoint-instances/task-errors/:taskErrorId/retry",
    {
      taskErrorId,
    },
    {}
  );
export const GET_CANDIDATES_TASK_ERRORS = (candidateId, search, offset, sort) =>
  UrlParamsReplace(
    "/task-errors/candidates/:candidateId",
    {
      candidateId,
    },
    {
      search,
      offset,
      sort,
    }
  );

//senders

export const ADD_SENDERS = (companyId) =>
  UrlParamsReplace("/companies/:companyId/senders", {
    companyId,
  });

export const GET_SENDERS = (companyId, search) =>
  UrlParamsReplace(
    "/companies/:companyId/senders",
    {
      companyId,
    },
    { search }
  );

export const CHECK_SENDER_USED = (companyId, senderId) =>
  UrlParamsReplace("/companies/:companyId/senders/:senderId", {
    companyId,
    senderId,
  });

export const DELETE_SENDER = (companyId, senderId) =>
  UrlParamsReplace("/companies/:companyId/senders/:senderId", {
    companyId,
    senderId,
  });

export const UPDATE_SENDER = (companyId, senderId) =>
  UrlParamsReplace("/companies/:companyId/senders/:senderId", {
    companyId,
    senderId,
  });
//html templates
export const ADD_HTML_TEMPLATE_URL = (companyId) =>
  UrlParamsReplace("/companies/:companyId/html-templates", { companyId });
export const GET_HTML_TEMPLATE_URL = (htmlTemplateId) =>
  UrlParamsReplace("/html-templates/:htmlTemplateId", { htmlTemplateId });
export const GET_HTML_TEMPLATES_URL = (
  companyId,
  search,
  offset,
  limit,
  sort,
  status
) =>
  UrlParamsReplace(
    "/companies/:companyId/html-templates",
    {
      companyId,
    },
    {
      search,
      offset,
      limit,
      sort,
      status,
    }
  );
export const DELETE_HTML_TEMPLATE_URL = (companyId, htmlTemplateId) =>
  UrlParamsReplace("/companies/:companyId/html-templates/:htmlTemplateId", {
    companyId,
    htmlTemplateId,
  });
export const ADD_CUSTOM_CSS_TO_HTML_TEMPLATE_URL = (companyId) =>
  UrlParamsReplace("/companies/:companyId/html-templates/custom-css", {
    companyId,
  });

export const UPDATE_TOUCHPOINT_INSTANCE_SURVERY_ANSWERE = (id) =>
  UrlParamsReplace("/touchpoint-instances/:id/survery-answers", { id });

export const ADD_SURVEY_TEMPLATE_URL = (companyId) =>
  UrlParamsReplace("/companies/:companyId/survey-templates", { companyId });

export const GET_SURVEY_TEMPLATES_URL = (
  companyId,
  search,
  status,
  offset,
  limit,
  sort
) =>
  UrlParamsReplace(
    "/companies/:companyId/survey-templates",
    {
      companyId,
    },
    {
      search,
      offset,
      limit,
      sort,
      status,
    }
  );

export const GET_SURVEY_TEMPLATE_URL = (surveyTemplateId) =>
  UrlParamsReplace("/survey-templates/:surveyTemplateId", { surveyTemplateId });

export const GET_DESCRIPTION_URL = (surveyTemplateId) =>
  UrlParamsReplace("/survey-templates/:surveyTemplateId/description", {
    surveyTemplateId,
  });

export const ADD_CANDIDATE_NEWSFEED_URL = (companyId) =>
  UrlParamsReplace("/companies/:companyId/candidate-news-feeds", { companyId });
export const GET_CANDIDATE_NEWSFEED_URL = (companyId, candidateId) =>
  UrlParamsReplace("/companies/:companyId/candidate-news-feeds/:candidateId", {
    companyId,
    candidateId,
  });

//audit logs

export const GET_AUDIT_LOGS_BY_ENTITY_ID = (companyId, entityId, isCandidate) =>
  UrlParamsReplace(
    "/companies/:companyId/audit-logs/:entityId",
    {
      companyId,
      entityId,
    },
    {
      isCandidate: isCandidate,
    }
  );

// time-slots

export const GET_TIME_SLOTS_URL = (companyId, touchPointInstanceId) =>
  UrlParamsReplace("/companies/:companyId/timeSlots/:touchPointInstanceId", {
    companyId,
    touchPointInstanceId,
  });

export const GET_BOOKED_TIME_SLOTS_URL = (companyId, touchPointInstanceId) =>
  UrlParamsReplace(
    "/companies/:companyId/booked/timeSlots/:touchPointInstanceId",
    {
      companyId,
      touchPointInstanceId,
    }
  );

export const GET_TIME_SLOT_BY_ID_URL = (timeSlotId) =>
  UrlParamsReplace("/timeSlots/:timeSlotId", { timeSlotId });

export const CREATE_TIME_SLOTS_URL = ({
  companyId,
  userId,
  touchPointInstanceId,
  timeSlots,
  timeZone,
}) =>
  UrlParamsReplace(
    "/companies/:companyId/timeSlots",
    {
      companyId,
    },
    {
      userId,
      touchPointInstanceId,
      timeSlots,
      timeZone,
    }
  );

export const SEND_NEED_HELP_EMAIL_URL = ({
  companyId,
  userId,
  touchPointInstanceId,
}) =>
  UrlParamsReplace(
    "/companies/:companyId/timeSlots/needHelp",
    {
      companyId,
    },
    {
      userId,
      touchPointInstanceId,
    }
  );

export const UPDATE_TIME_SLOT_STATUS_URL = (timeSlotId, status) =>
  UrlParamsReplace(
    "/timeSlots/:timeSlotId",
    {
      timeSlotId,
    },
    { status }
  );

//meeting-link
export const GET_MEETING_LINKS_URL = (companyId, search, offset, limit) =>
  UrlParamsReplace(
    "/companies/:companyId/meetingLinks",
    {
      companyId,
    },
    {
      search,
      offset,
      limit,
    }
  );

export const UPDATE_MEETING_LINK_URL = (companyId, meetingLinkId) =>
  UrlParamsReplace(
    "/companies/:companyId/meetingLinks/:meetingLinkId",
    {
      companyId,
      meetingLinkId,
    },
    {}
  );

export const DELETE_MEETING_LINK_URL = (companyId, meetingLinkId) =>
  UrlParamsReplace(
    "/companies/:companyId/meetingLinks/:meetingLinkId",
    {
      companyId,
      meetingLinkId,
    },
    {}
  );

export const SMTP_EMAIL_TRACKING_URL = (touchPointInstanceId, url) =>
  UrlParamsReplace(
    "/smtp-email-tracking",
    {},
    {
      url,
      touchPointInstanceId,
    }
  );

//meeting-location
export const GET_MEETING_LOCATION_URL = (companyId, search, offset, limit) =>
  UrlParamsReplace(
    "/companies/:companyId/meeting-location",
    {
      companyId,
    },
    {
      search,
      offset,
      limit,
    }
  );

export const SEND_EMAIL_URL = UrlParamsReplace("/send-email");

export const GET_EMAIL_VARIANTS_URL = UrlParamsReplace("/emailVariants");

export const GENERATE_SURVEY_REPORT = (campaignTemplateId) =>
  UrlParamsReplace("/campaign-templates/:campaignTemplateId/survey-result", {
    campaignTemplateId,
  });

// export const GET_EMAIL_VARIANTS_URL = (
//   subject,
//   mainPoints,
//   keywords,
//   tone,
//   words,
//   variants,
//   trackingTemplateId
// ) => {
//   return UrlParamsReplace(
//     "/emailVariants",
//     {},
//     {
//       subject,
//       mainPoints,
//       keywords,
//       tone,
//       words,
//       variants,
//       trackingTemplateId,
//     }
//   );
// };

//linkedin posts
export const GET_LINKEDIN_POST_URL = (companyId, search, offset, limit) =>
  UrlParamsReplace(
    "/companies/:companyId/linkedinPost",
    {
      companyId,
    },
    {
      search,
      offset,
      limit,
    }
  );

export const UPDATE_LINKEDIN_POST_URL = (companyId, linkedinPostId) =>
  UrlParamsReplace(
    "/companies/:companyId/linkedinPost/:linkedinPostId",
    {
      companyId,
      linkedinPostId,
    },
    {}
  );

export const DOMO_REPORT_URL = (companyId, search, offset, limit) =>
  UrlParamsReplace(
    "/companies/:companyId/domoReports",
    {
      companyId,
    },
    {
      search,
      offset,
      limit,
    }
  );

export const DOMO_REPORT_BY_ID_URL = (companyId, id) =>
  UrlParamsReplace("/companies/:companyId/domoReports/:id", {
    companyId,
    id,
  });

export const DELETE_LINKEDIN_POST_URL = (companyId, postID) =>
  UrlParamsReplace(
    "/companies/:companyId/linkedinPost/:postID",
    {
      companyId,
      postID,
    },
    {}
  );

export const GET_INTERVENTION_CHECKPOINTS = () =>
  UrlParamsReplace("/intervention_checkpoints");

export const VALIDATE_USERS_FROM_CSV_URL = (companyId) =>
  UrlParamsReplace("/companies/:companyId/bulk-users-validations", {
    companyId,
  });

export const ADD_BULK_USERS_FROM_CSV_URL = (companyId) =>
  UrlParamsReplace("/companies/:companyId/bulk-users-create", { companyId });

export const MODIFY_DOMO_REPORT_URL = (companyId, id) =>
  UrlParamsReplace(
    "/companies/:companyId/domoReports/:id",
    {
      companyId,
      id,
    },
    {}
  );

export const GET_POSITIONS_BULK_DOWNLOAD_URL = (
  companyId,
  search,
  offset,
  limit,
  sort,
  filters
) =>
  UrlParamsReplace(
    "/companies/:companyId/positions-bulk-download",
    {
      companyId,
    },
    {
      search,
      offset,
      limit,
      sort,
      filters,
    }
  );

export const ADD_SUPER_ADMIN_TOUCHPOINT_URL = () =>
  UrlParamsReplace("/journeys/touch-points");

export const GET_TOUCHPOINTS = (
  search = null,
  filters = null,
  offset = null,
  listing = false,
  limit = 10
) =>
  UrlParamsReplace(
    "/journeys/touch-points",
    {},
    { search, filters, offset, listing, limit }
  );

export const GET_TOUCHPOINT_BY_ID = (touchpointID) =>
  UrlParamsReplace("/touchpoint/:touchpointID", { touchpointID });

export const ADD_JOURNEY = (companyId) =>
  UrlParamsReplace("/companies/:companyId/journey", {
    companyId,
  });

export const GET_JOURNEY_TEMPLATES_URL = (companyId) =>
  UrlParamsReplace("/journey-templates?companyId=:companyId", {
    companyId,
  });

export const GET_JOURNEY = (offset, search, sort, status) =>
  UrlParamsReplace(
    "/journey-templates",
    {},
    {
      offset,
      search,
      status,
      sort,
    }
  );

export const GET_ADMIN_JOURNEY = () =>
  UrlParamsReplace("/journey-templates-for-use");

export const GET_JOURNEY_CATEGORY = (
  search = null,
  offset = null,
  limit = null
) => UrlParamsReplace("/journey-categories", {}, { search, offset, limit });

export const GET_JOURNEY_CATEGORY_BY_ID = (id) =>
  UrlParamsReplace("/journey-categories/:id", { id });

export const ADD_JOURNEY_CATEGORY = () =>
  UrlParamsReplace("/journey-categories");

export const UPDATE_JOURNEY_CATEGORY = (id) =>
  UrlParamsReplace("/journey-categories/:id", { id });

export const UPDATE_SUPER_ADMIN_TOUCHPOINT_URL = (id) => UrlParamsReplace("/touchpoint/:id", { id });

export const EDIT_JOURNEY = ({ companyId, templateId }) =>
  UrlParamsReplace("/companies/:companyId/journey/:templateId", {
    companyId,
    templateId,
  });

export const UPDATE_SUPER_ADMIN_TOUCHPOINT_STATUS_URL = (id) => UrlParamsReplace("/touchpoint/:id/status", { id });
