import { emailRegex } from "constants";

export const validateInternalTouchPoint = (touchPoint, isCompanyAdmin) => {
    let errors = {
    };
    if (touchPoint.title === "") {
        errors.title = "Touch point title is required";
    }
    if (!touchPoint.to) {
        errors.to = "Touch point to is required";
    }
    if (touchPoint.subject === "") {
        errors.subject = "Touch point subject is required";
    }
    if (touchPoint.html === "") {
        errors.html = "Touch point body content is required";
    }
    if(isCompanyAdmin && !touchPoint.from) {
        errors.from = "Company admin can not send email from company";
    }
    if (touchPoint?.cc?.value === "other" && !emailRegex.test(touchPoint?.ccEmail)) {
        errors.ccEmail = "Add valid email";
    }
    if (touchPoint?.to?.value === "other" && !emailRegex.test(touchPoint?.toEmail)) {
        errors.toEmail = "Add valid email";
    }

    if (
        Object.keys(errors).length === 0
    ) {
        return undefined;
    }
    return errors;
};
