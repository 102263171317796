import React from 'react';
import PropTypes from 'prop-types';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ListItem from 'atoms/ListItems';
import ListItemText from 'atoms/ListItemText';
import { ArrowIcon } from 'atoms/ArrowIcon';
import useSuperadminJourneysDropdown from './hooks/useSuperadminJourneysDropdown';


const SuperadminJourneysDropdown = ({ menuActive }) => {
  const { open, selectedIndex, journeyItems, handleToggle, handleListItemClick } = useSuperadminJourneysDropdown();

  return (
    <div className={`text-gray-500 rounded-lg ml-1`}>
      <ListItem
        onClick={handleToggle}
        selected={open}
        isParent={true}
        icon={open ? '/images/icons/speaker-o.svg' : '/images/icons/speaker.svg'}
      >
        <ListItemText primary="Journeys" selected={open} isParent={true} />
        <ArrowIcon>{open ? <ExpandLess /> : <ExpandMore />}</ArrowIcon>
      </ListItem>

      <div className={`collapse ${open ? 'open' : ''}`}>
        {journeyItems.map((item, index) => (
          <ListItem
            key={index}
            selected={selectedIndex === index}
            onClick={() => handleListItemClick(index, item.path)}
          >
            <ListItemText primary={item.primary} selected={selectedIndex === index} />
          </ListItem>
        ))}
      </div>
    </div>
  );
};

SuperadminJourneysDropdown.propTypes = {
  menuActive: PropTypes.bool.isRequired,
};

export default SuperadminJourneysDropdown;
