export const timezones = [
  {
    timezone: "Africa/Abidjan",
    code: "GMT",
    format: "(UTC ±0)",
    locations: "Ivory Coast",
  },
  {
    timezone: "Africa/Cairo",
    code: "EET",
    format: "(UTC +2)",
    locations: "Egypt",
  },
  {
    timezone: "Africa/Casablanca",
    code: "WET",
    format: "(UTC ±0 / UTC +1 Daylight)",
    locations: "Morocco",
  },
  {
    timezone: "Africa/Johannesburg",
    code: "SAST",
    format: "(UTC +2)",
    locations: "South Africa",
  },
  {
    timezone: "Africa/Lagos",
    code: "WAT",
    format: "(UTC +1)",
    locations: "Nigeria",
  },
  {
    timezone: "Africa/Nairobi",
    code: "EAT",
    format: "(UTC +3)",
    locations: "Kenya",
  },
  {
    timezone: "Africa/Tunis",
    code: "CET",
    format: "(UTC +1)",
    locations: "Tunisia",
  },
  {
    timezone: "Africa/Tripoli",
    code: "EET",
    format: "(UTC +2)",
    locations: "Libya",
  },
  {
    timezone: "Africa/Windhoek",
    code: "CAT",
    format: "(UTC +2)",
    locations: "Namibia",
  },
  {
    timezone: "America/Anchorage",
    code: "AKST",
    format: "(UTC -9 / UTC -8 Daylight)",
    locations: "Alaska, US",
  },
  //   {
  //     timezone: "America/Argentina/Buenos_Aires",
  //     code: "ART",
  //     format: "(UTC -3)",
  //     locations: "Argentina",
  //   },
  {
    timezone: "America/Bogota",
    code: "COT",
    format: "(UTC -5)",
    locations: "Colombia",
  },
  //   {
  //     timezone: "America/Caracas",
  //     code: "VET",
  //     format: "(UTC -4)",
  //     locations: "Venezuela",
  //   },
  {
    timezone: "America/Chicago",
    code: "CST",
    format: "(UTC -6 / UTC -5 Daylight)",
    locations: "Central US, Mexico",
  },
  {
    timezone: "America/Denver",
    code: "MST",
    format: "(UTC -7 / UTC -6 Daylight)",
    locations: "Mountain US, Canada",
  },
  {
    timezone: "America/Los_Angeles",
    code: "PST",
    format: "(UTC -8 / UTC -7 Daylight)",
    locations: "Western US, Canada",
  },
  {
    timezone: "America/Mexico_City",
    code: "CST",
    format: "(UTC -6 / UTC -5 Daylight)",
    locations: "Mexico",
  },
  {
    timezone: "America/New_York",
    code: "EST",
    format: "(UTC -5 / UTC -4 Daylight)",
    locations: "Eastern US, Canada",
  },
  //   {
  //     timezone: "America/Sao_Paulo",
  //     code: "BRT",
  //     format: "(UTC -3)",
  //     locations: "Brazil",
  //   },
  {
    timezone: "America/Toronto",
    code: "EST",
    format: "(UTC -5 / UTC -4 Daylight)",
    locations: "Eastern Canada",
  },
  {
    timezone: "America/Vancouver",
    code: "PST",
    format: "(UTC -8 / UTC -7 Daylight)",
    locations: "Western Canada",
  },
  {
    timezone: "Asia/Almaty",
    code: "ALMT",
    format: "(UTC +6)",
    locations: "Kazakhstan",
  },
  {
    timezone: "Asia/Amman",
    code: "EET",
    format: "(UTC +2 / UTC +3 Daylight)",
    locations: "Jordan",
  },
  {
    timezone: "Asia/Baghdad",
    code: "AST",
    format: "(UTC +3)",
    locations: "Iraq",
  },
  {
    timezone: "Asia/Bangkok",
    code: "ICT",
    format: "(UTC +7)",
    locations: "Thailand",
  },
  {
    timezone: "Asia/Beirut",
    code: "EET",
    format: "(UTC +2 / UTC +3 Daylight)",
    locations: "Lebanon",
  },
  {
    timezone: "Asia/Colombo",
    code: "IST",
    format: "(UTC +5:30)",
    locations: "Sri Lanka",
  },
  {
    timezone: "Asia/Dhaka",
    code: "BST",
    format: "(UTC +6)",
    locations: "Bangladesh",
  },
  {
    timezone: "Asia/Dubai",
    code: "GST",
    format: "(UTC +4)",
    locations: "UAE",
  },
  {
    timezone: "Asia/Hong_Kong",
    code: "HKT",
    format: "(UTC +8)",
    locations: "Hong Kong",
  },
  {
    timezone: "Asia/Jakarta",
    code: "WIB",
    format: "(UTC +7)",
    locations: "Indonesia",
  },
  {
    timezone: "Asia/Karachi",
    code: "PKT",
    format: "(UTC +5)",
    locations: "Pakistan",
  },
  {
    timezone: "Asia/Kolkata",
    code: "IST",
    format: "(UTC +5:30)",
    locations: "India",
  },
  {
    timezone: "Asia/Kuala_Lumpur",
    code: "MYT",
    format: "(UTC +8)",
    locations: "Malaysia",
  },
  {
    timezone: "Asia/Manila",
    code: "PHT",
    format: "(UTC +8)",
    locations: "Philippines",
  },
  {
    timezone: "Asia/Riyadh",
    code: "AST",
    format: "(UTC +3)",
    locations: "Saudi Arabia",
  },
  {
    timezone: "Asia/Shanghai",
    code: "CST",
    format: "(UTC +8)",
    locations: "China",
  },
  {
    timezone: "Asia/Singapore",
    code: "SGT",
    format: "(UTC +8)",
    locations: "Singapore",
  },
  {
    timezone: "Asia/Tokyo",
    code: "JST",
    format: "(UTC +9)",
    locations: "Japan",
  },
  {
    timezone: "Asia/Yerevan",
    code: "AMT",
    format: "(UTC +4)",
    locations: "Armenia",
  },
  {
    timezone: "Europe/Amsterdam",
    code: "CET",
    format: "(UTC +1 / UTC +2 Daylight)",
    locations: "Netherlands",
  },
  {
    timezone: "Europe/Athens",
    code: "EET",
    format: "(UTC +2 / UTC +3 Daylight)",
    locations: "Greece",
  },
  {
    timezone: "Europe/Berlin",
    code: "CET",
    format: "(UTC +1 / UTC +2 Daylight)",
    locations: "Germany",
  },
  {
    timezone: "Europe/Istanbul",
    code: "TRT",
    format: "(UTC +3)",
    locations: "Turkey",
  },
  {
    timezone: "Europe/Lisbon",
    code: "WET",
    format: "(UTC ±0 / UTC +1 Daylight)",
    locations: "Portugal",
  },
  {
    timezone: "Europe/London",
    code: "GMT",
    format: "(UTC ±0 / UTC +1 Daylight)",
    locations: "United Kingdom",
  },
  {
    timezone: "Europe/Madrid",
    code: "CET",
    format: "(UTC +1 / UTC +2 Daylight)",
    locations: "Spain",
  },
  {
    timezone: "Europe/Moscow",
    code: "MSK",
    format: "(UTC +3)",
    locations: "Russia",
  },
  {
    timezone: "Europe/Paris",
    code: "CET",
    format: "(UTC +1 / UTC +2 Daylight)",
    locations: "France",
  },
  {
    timezone: "Europe/Rome",
    code: "CET",
    format: "(UTC +1 / UTC +2 Daylight)",
    locations: "Italy",
  },
  {
    timezone: "Europe/Zurich",
    code: "CET",
    format: "(UTC +1 / UTC +2 Daylight)",
    locations: "Switzerland",
  },
  {
    timezone: "Pacific/Auckland",
    code: "NZST",
    format: "(UTC +12 / UTC +13 Daylight)",
    locations: "New Zealand",
  },
  {
    timezone: "Pacific/Fiji",
    code: "FJT",
    format: "(UTC +12 / UTC +13 Daylight)",
    locations: "Fiji",
  },
  {
    timezone: "Pacific/Honolulu",
    code: "HST",
    format: "(UTC -10)",
    locations: "Hawaii, US",
  },
  {
    timezone: "Australia/Sydney",
    code: "AEST",
    format: "(UTC +10 / UTC +11 Daylight)",
    locations: "Sydney, Australia",
  },
  {
    timezone: "Australia/Canberra",
    code: "AEST",
    format: "(UTC +10 / UTC +11 Daylight)",
    locations: "Canberra, Australia",
  },
  {
    timezone: "Australia/Brisbane",
    code: "AEST",
    format: "(UTC +10 / UTC +11 Daylight)",
    locations: "Brisbane, Australia",
  },
  {
    timezone: "Australia/Darwin",
    code: "ACST",
    format: "(UTC +9:30)",
    locations: "Darwin, Australia",
  },
  {
    timezone: "Australia/Perth",
    code: "AWST",
    format: "(UTC +8)",
    locations: "Perth, Australia",
  },
  {
    timezone: "Australia/Lord_Howe",
    code: "LHST",
    format: "(UTC +10:30 / UTC +11 Daylight)",
    locations: "Lord Howe Island, Australia",
  },
];