import React from 'react';
import PropTypes from 'prop-types';

const ListItem = ({ children, onClick, selected, isParent, icon }) => (
  <div
    className={`list-item ${selected ? 'selected' : ''} ${isParent ? 'parent' : ''}`}
    onClick={onClick}
  >
    {icon && <img src={icon} alt="icon" className="icon" />}
    {children}
  </div>
);

ListItem.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  isParent: PropTypes.bool,
  icon: PropTypes.string,
};

ListItem.defaultProps = {
  selected: false,
  isParent: false,
  icon: null,
};

export default ListItem;
