import { getCompanyId } from "services/identity.service";
import { commonTransform } from "../handleTransformation";
import { GET_USER_FILTERS_URL, GET_USERS_URL } from "services/url.service";

export const userMutations = {};

export const userQueries = {
  getUsers : {
    getUrl: ({ search, offset, limit, sort, filters, companyId } ) => {
        return GET_USERS_URL(companyId, search, offset, limit, filters, sort)
    },
    transformResponse: (res) => commonTransform(res),
    method: "GET",
  },
  getUserFilters : {
    getUrl: (
      { filters , companyId}
    ) => {
        return GET_USER_FILTERS_URL(companyId, JSON.stringify(
          filters
        ))
    },
    transformResponse: (res) => commonTransform(res),
    method: "GET",
  }
};
