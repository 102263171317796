import { getCompanyId } from "services/identity.service";
import { commonTransform } from "../handleTransformation";
import { GET_DESIGNATIONS_URL } from "services/url.service";

export const designationMutations = {};

export const designationQueries = {
  getDesignations : {
    getUrl: () => {
        const companyId = getCompanyId();
        return GET_DESIGNATIONS_URL(companyId)
    },
    transformResponse: (res) => commonTransform(res),
    method: "GET",
  },
};
