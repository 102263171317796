export const commonTransform = (res) => {
  return res.entity || res;
};

export const getSendersTransform = (res) => {
  return res?.entity?.rows?.map((item) => ({
    label: item?.email,
    value: item?.id,
  }));
};

export const formatGetTouchPointTemplate = (res) => {
  return res?.entity?.rows?.map((item) => ({
    body: item.bodyHtmlTemplate,
    emailBody: item.emailBodyHtmlTemplate,
    id: item.id,
    surveyTemplate: item.surveyTemplate,
    name: item.title,
    image: item.tpImageUrl,
    type: item.type,
  }));
};

export const journeyCategoryTransformer = (res) => {
  return res?.entity?.rows?.map((item) => ({
    label: item?.name,
    value: item?.id,
  }));
};

export const meetingLocationTransformer = (res) => {
  return res?.entity?.rows?.map((item) => ({
    label: item?.location,
    value: item?.id,
  }));
};

export const formatGetTouchPointTemplateList = (res) => {
  let data = {
    count: res.entity.count,
    rows: res.entity.rows.map((item) => ({
      ...item,
      image: item.tpImageUrl,
      user: item.User,
    })),
  };
  return data;
};

export const globalTransform = (res) => {
  return res;
};
