export const API_END_POINT = 'http://localhost:3000/';
export const COUNTRY_ICONS = process.env.REACT_APP_COUNTRY_ICONS;

export const DEFAULT_PAGE = 1;
export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_SET_TOTAL_PAGES = 1;
export const TOTAL_PAGES = 1;
export const TOTAL = 1;
export const MAXIMUM_FILE_SIZE = 5242880;

export const STATE_CODES = {
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    INTERNAL_SERVER_ERROR: 500,
    BAD_GATEWAY: 502,
    SERVICE_UNAVAILABLE: 503,
    GATEWAY_TIMEOUT: 504,
    SUCCESS: 200
}
