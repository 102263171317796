import logger from "redux-logger";
import { apiSlice } from "../utils/api/baseSlice";
import NewJourneySlice from "./new-journey";
// persistance commented for temporary performance issue it will be added later
// import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' 

const { configureStore, combineReducers } = require("@reduxjs/toolkit");

// const persistConfig = {
//   key: 'root',
//   storage,
//   whitelist: ['NewJourneySlice'],
//   blacklist: [apiSlice.reducerPath],
// }

const rootReducer = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
    newJourney: NewJourneySlice,
});

// const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware().concat(apiSlice.middleware)
      // .concat(logger); // when you want to log the redux action actions enable this
  },
  devTools: process.env.NEXT_PUBLIC_LOGGER === "true",
});

// export const persistor = persistStore(store);