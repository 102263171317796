import { getCompanyId } from "services/identity.service";
import { commonTransform } from "../handleTransformation";
import { GET_COMPANY_BY_USER_ID, GET_COMPANY_URL, UPDATE_COMPANY_SETTINGS } from "services/url.service";

export const companyMutations = {
    updateCompanySettings : {
        getUrl: () => {
            const companyId = getCompanyId();
            return UPDATE_COMPANY_SETTINGS(companyId)
        },
        transformResponse: (res) => commonTransform(res),
        method: "PUT",
    },
};

export const companyQueries = {
   getCompany : {
    getUrl: () => {
        const companyId = getCompanyId();
        return GET_COMPANY_URL(companyId)
    },
    transformResponse: (res) => commonTransform(res),
    method: "GET",
  },
  getCompaniesByUserId: {
    getUrl: (userId) =>  GET_COMPANY_BY_USER_ID(userId),
    transformResponse: (res) => commonTransform(res),
    method: "GET",
  }
};
