import { commonTransform } from "../handleTransformation";

export const domoMutations = {};

export const domoQueries = {
  domo: {
    getUrl: (params) =>
      `/companies/${params.companyId}/domoReports?search=&offset=0&limit=15`,
    transformResponse: (res) => commonTransform(res),
    method: "GET",
  },
};
